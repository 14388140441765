let baseUrl = "";
let env = process.env;
if (env.NODE_ENV === "development") {
  baseUrl = "http://192.168.1.102:7001/api/v1";
} else {
  if (env.VUE_APP_MODE == "beta") {
    baseUrl = "https://ygzf-api.hwxld.cn/api/v1";
  } else if (env.VUE_APP_MODE == "buildHttp") {
    baseUrl = "http://ygzf.wzhrss.gov.cn/api/v1";
  } else if (env.VUE_APP_MODE == "buildHttps") {
    baseUrl = "https://ygzf.wzhrss.gov.cn/api/v1";
  } else {
    baseUrl = "http://ygzf.wzhrss.gov.cn/api/v1";
  }
}
export default baseUrl;
