<template>
    <el-image :src="src" v-if="!isPdf" :preview-src-list="list" class="info-pic"></el-image>
    <img v-else src="../../assets/images/pdf.png" @click="download" class="info-pdf">
</template>

<script>
    export default {
        name: "",
        data() {
            return {}
        },
        computed: {
            isPdf() {
                let src = this.src;
                return src && src.toLowerCase().includes('.pdf')
            }
        },
        props: {
            src: {
                type: String,
                required: true
            },
            list: {
                type: Array
            }
        },
        methods: {
            async download() {
                window.open(this.src, "_blank")
            }
        }
    }
</script>

<style scoped>
    .info-pic {
        width: 100px;
        height: 100px;
        margin-right: 20px;
        cursor: pointer;
    }

    .info-pdf {
        margin-right: 20px;
        cursor: pointer;
    }
</style>
