<template>
    <el-select
        :clearable="clearable"
        :filterable="filterable"
        :multiple="multiple"
        :disabled="disabled"
        v-model="val"
        :placeholder="holder"
        :size="size"
        class="async-select"
        @change="select">
        <el-option
            v-for="(item,index) in list"
            :key="index" :label="item.dicValue"
            :value="item.dicNo">
        </el-option>
    </el-select>
</template>

<script>
    import {Dict} from "../../util/api";

    export default {
        name: "",
        data() {
            return {
                val: this.mode,
                list: []
            }
        },
        watch: {
            mode(val) {
                this.val = val;
            },
            code(val) {
                if (val) {
                    this.init()
                }
            }

        },
        model: {
            prop: "mode"
        },
        props: {
            mode: {
                type: [Number, String, Object, Array]
            },
            holder: {
                type: String
            },
            size: {
                type: String,
                default: "small"
            },
            filterable: {
                type: Boolean,
                default: true
            },
            clearable: {
                type: Boolean,
                default: true
            },
            multiple: {
                type: Boolean,
                default: false
            },
            code: {
                type: [String, Number],
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                this.select('');
                if (this.disabled) {
                    return false
                }
                let params = {
                    PDicNo: this.code
                }
                let res = await Dict.GetAreasByParent(params);
                if (res) {
                    this.list = res || [];
                }
            },
            select(val) {
                this.$emit("input", val);
                this.$emit("change", val);
            }
        }
    }
</script>

<style scoped>
    .async-select {
        width: 200px;
    }
</style>
