<template>
    <el-upload
        class="upload-button"
        :action="$BASE_URL + '/File/UpFile'"
        :headers="headers"
        :disabled="disabled"
        :before-upload="beforeUpload"
        :on-success="uploadSuccess"
        :on-error="onError"
        :show-file-list="false"
        :multiple="false">
        <el-button :size="size" type="primary">
            <slot></slot>
        </el-button>
    </el-upload>
</template>

<script>
    export default {
        name: "",
        data() {
            return {
                fileName: ''
            }
        },
        computed: {
            headers() {
                let token = this.$store.state.UserModule.token
                return {
                    'X-Token': token
                }
            },
        },
        props: {
            size: {
                type: String,
                default: 'small'
            },
            disabled: {
                type: Boolean,
                default: false
            },
            accept: {
                type: Array,
                required: true
            },
        },
        methods: {
            beforeUpload(file) {
                if (!file) {
                    return false
                }
                let nameArr = file.name.split(".");
                let suffix = nameArr[nameArr.length - 1];
                let types = this.accept;
                if (types.includes(suffix.toLowerCase())) {
                    this.fileName = file.name;
                    return true
                } else {
                    this.$message.error(`只支持上传格式的${types.join(',')}文件!`)
                    return false
                }
            },
            uploadSuccess(res) {
                if (res.isSuccess) {
                    let data = {
                        url: res.data,
                        name: this.fileName
                    }
                    this.$emit('success', data)
                } else {
                    this.$message.error(res.message);
                }
            },
            onError(err) {
                this.$message.error(JSON.stringify(err))
            },
        }
    }
</script>

<style scoped>
    .upload-button {
        display: inline-block;
    }
</style>
