<template>
    <div class="v-table collapse-transition" :style="{ height: height || autoHeight }">
        <el-table
            ref="vtable"
            :row-key="rowKey"
            v-loading="loading"
            @row-click="rowClick"
            @select="select"
            @select-all="select"
            :data="list" border
            :size="size" stripe
            :span-method="spanMethod"
            style="width: 100%"
            :height="tableHeight"
            highlight-current-row
            :show-summary="showSummary"
            :header-cell-style="headerStyle"
            :cell-style="cellStyle"
            :summary-method="summaryMethod"
            :row-style="rowStyle">
            <el-table-column v-if="check" :reserve-selection="true" type="selection" align="center"
                             width="55"></el-table-column>
            <el-table-column v-if="index" type="index" align="center" label="序号" width="50"></el-table-column>
            <template v-for="(item,index) in column">
                <template v-if="item.tooltip">
                    <el-table-column
                        :key="index"
                        :prop="item.prop"
                        :width="item.width"
                        :label="item.label"
                        :align="item.align || 'center'"
                        :formatter="item.formatter"
                        header-align="center">
                        <template slot-scope="{ row }">
                            <el-popover placement="top" trigger="hover" :content="row[item.prop]">
                                <span slot="reference" style="white-space: nowrap;">{{ row[item.prop] }}</span>
                            </el-popover>
                        </template>
                    </el-table-column>
                </template>
                <template v-else>
                    <el-table-column
                        v-if="!item.slot && !item.hide"
                        :key="index" :prop="item.prop"
                        :width="item.width" :label="item.label"
                        :align="item.align || 'center'"
                        :formatter="item.formatter"
                        :filter-method="item.filters ? (a, b, c) => filterHandler(a, b, c, item.prop) : undefined"
                        header-align="center">
                    </el-table-column>
                    <el-table-column
                        v-if="item.slot && !item.hide"
                        :key="index" :label="item.label"
                        :prop="item.prop" :width="item.width"
                        :align="item.align || 'center'">
                        <template slot-scope="{ row, $index }">
                            <slot
                                :name="item.slot" :index="$index"
                                :row="row" :value="row[item.prop]"
                                :state="item.state" :prop="item.prop"
                                :sub-prop="item.subProp">
                            </slot>
                        </template>
                    </el-table-column>
                </template>
            </template>
        </el-table>
        <el-pagination
            class="page"
            v-if="page"
            background
            @current-change="handlePage"
            :current-page="page.PageIndex"
            :total="page.PageTotal"
            :page-size="page.PageSize">
        </el-pagination>
        <div class="footer" v-if="footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>
<script>
    //import bus from "@/lib/bus"
    export default {
        name: "",
        data() {
            return {
                autoHeight: ""
            }
        },
        computed: {
            tableHeight() {
                if (!this.height && !this.autoHeight) return null
                if (!this.page) {
                    return "100%";
                } else {
                    return "calc(100% - 45px)";
                }
            },
        },
        props: {
            list: {
                required: true,
                type: Array
            },
            column: {
                type: Array,
                required: true
            },
            height: {
                type: String
            },
            page: {
                required: true,
                type: [Object, Boolean],
                default: function () {
                    return {
                        PageIndex: 1,
                        PageTotal: 0,
                        PageSize: 20
                    }
                }
            },
            rowStyle: {
                type: [Function, Object],
                default: function () {
                    return {}
                }
            },
            cellStyle: {
                type: [Function, Object],
                default: function () {
                    return {}
                }
            },
            headerStyle: {
                type: [Function, Object],
                default: function () {
                    return {
                        backgroundColor: "#EFF5FD",
                        color: "#666666",
                        fontSize: "14px"
                    }
                }
            },
            footer: {
                type: Boolean,
                default: false
            },
            index: {
                type: Boolean,
                default: false
            },
            check: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: "mini"
            },
            rowKey: {
                type: String,
                default: "id"
            },
            loading: {
                type: Boolean,
                default: false
            },
            rowClick: {
                type: Function,
                default: function () {
                }
            },
            summaryMethod: {
                type: Function,
                default: function () {
                }
            },
            showSummary: {
                type: Boolean,
                default: false
            },
            spanMethod: {
                type: Function,
                default: function () {
                    return {
                        rowspan: 1,
                        colspan: 1
                    };
                }
            },
            heightAuto: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            filterHandler(value, row, column, prop) {
                return row[prop] == value;
            },
            handlePage(val) {
                this.$emit("page", val);
            },
            select(selection, row) {
                this.$emit("select", selection, row);
            },
            clear() {
                this.$refs["vtable"].clearSelection();
            },
            toggle() {
                this.$refs["vtable"].toggleAllSelection();
            },
            setHeight(h) {
                this.autoHeight = h ? `calc(100% - ${h})` : "";
            },
            resize() {
                this.$nextTick(() => {
                    let h = this.$parent.$el.children[0].offsetHeight;
                    if (this.heightAuto) {
                        this.autoHeight = null;
                    } else {
                        if (this.footer) {
                            this.autoHeight = h ? `calc(100% - ${h + 40}px)` : `calc(100% - 40px)`;
                        } else {
                            this.autoHeight = h ? `calc(100% - ${h}px)` : "";
                        }

                    }

                });
            }
        },
        mounted() {
            this.resize();
            window.onresize = () => this.resize();
        }
    }
</script>
<style scoped>
    .page {
        margin-top: 10px;
    }

    .v-table {
        position: relative;
        background-color: #fff;
    }

    .v-table .footer {
        height: 40px;
        line-height: 40px;
    }
</style>
