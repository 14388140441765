import {User} from '../../util/api';

const state = {
    isSalaryPayment: false,
    token: '',
    userInfo: {},
    noticeDialog: false,
    qixinToken: ''
}

const mutations = {
    setUserInfo(state, data) {
        if (data) {
            state.userInfo = data;
            state.token = data.token
        } else {
            state.userInfo = {};
            state.token = ''
        }
    },
    setNoticeDialog(state, data) {
        state.noticeDialog = data
    },
    setSalaryPayment(state, data) {
        state.isSalaryPayment = data
    },
    setQixinToken(state, data) {
        state.qixinToken = data.token
    },
}


const actions = {
    async autoLogin({commit}) {
        let res = await User.LoginByWzSixItems();
        commit('setUserInfo', res)
        return res
    },
    async login({commit}, data) {
        let res = await User.Login(data);
        commit('setUserInfo', res)
        return res
    },
    async QixinLogin({commit}, data) {
        let res = await User.EmbedLogin(data);
        commit('setQixinToken', res)
        return res
    },
    async getUserInfo({commit}, data) {
        commit('setUserInfo', data)
        return data;
    },
    async logout() {
        await User.LoginOut()
        sessionStorage.clear();
        window.location.href = '/'
    }
}

export default {
    namespaced: true,
    actions,
    state,
    mutations
}
