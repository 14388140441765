import $http from "@/util/http";

export const Company = {
  GovGetListByPages: (data, page) =>
    $http("Company/GovGetListByPages", data, "get", page),
  GetSafeguard: (data) => $http("Company/GetSafeguard", data),
  UpdateRegulatoryCategories: (data) =>
    $http("Company/UpdateRegulatoryCategories", data, "post"),
  GetCompanyInfo: (data) => $http("Company/GetCompanyInfo", data),
  GetCompanyProjectInfo: (data, page) =>
    $http("Company/GetCompanyProjectInfo", data, "get", page),
  QueryCompanyList: (data, page) =>
    $http("Company/QueryCompanyList", data, "get", page),
  GetCompanyList: (data, page) =>
    $http("Company/GetCompanyList", data, "get", page),
  GetAreaList: (data) => $http("Company/GetAreaList", data),
  GetQualificationAuthority: () => $http("Company/GetQualificationAuthority"),
  GetLikeCompanyList: (data) => $http("Company/GetLikeCompanyList", data),
  GetSmsRecordByPid: (data, page) =>
    $http("Company/GetSmsRecordByPid", data, "get", page),
  UpdateSendSMS: (data) => $http("Company/UpdateSendSMS", data, "post"),
};

export const CompanyLabourer = {
  GetLabourerListPage: (data, page) =>
    $http("CompanyLabourer/GetLabourerListPage", data, "get", page),
  GetLabourerInfo: (data) => $http("CompanyLabourer/GetLabourerInfo", data),
  GetLabourerByIdCardNoInfo: (data) =>
    $http("CompanyLabourer/GetLabourerByIdCardNoInfo", data),
  GetLabourerResumeList: (data, page) =>
    $http("CompanyLabourer/GetLabourerResumeList", data, "get", page),
  GetSalaryHistoryList: (data, page) =>
    $http("CompanyLabourer/GetSalaryHistoryList", data, "get", page),
  GetLabourerAllListPage: (data, page) =>
    $http("CompanyLabourer/GetLabourerAllListPage", data, "get", page),
  GetLabourerResumeByIdCarcNoList: (data, page) =>
    $http("CompanyLabourer/GetLabourerResumeByIdCarcNoList", data, "get", page),
};
export const PayrollProxy = {
  GetListByPages: (data, page) =>
    $http("PayrollProxy/GetListByPages", data, "get", page),
  GetDetailListByPages: (data, page) =>
    $http("PayrollProxy/GetDetailListByPages", data, "get", page),
  GetProxyPayDetails: (data, page) =>
    $http("PayrollProxy/GetProxyPayDetails", data, "get", page),
  GetProjectCommissionAttachmentList: (data, page) =>
    $http("PayrollProxy/GetProjectCommissionAttachmentList", data, "get", page),
};

export const UserRole = {
  QueryRoleList: () => $http("UserRole/QueryRoleList"),
  GetAuthMenuTree: (data) => $http("UserRole/GetAuthMenuTree", data),
  GetUserRoleList: (data, page) =>
    $http("UserRole/GetUserRoleList", data, "get", page),
  GetRoleList: () => $http("UserRole/GetRoleList"),
  GetUserRoleInfo: (data) => $http("UserRole/GetUserRoleInfo", data),
  SaveUserRoleInfo: (data) => $http("UserRole/SaveUserRoleInfo", data, "post"),
  DeleteUserRoleInfo: (data) =>
    $http("UserRole/DeleteUserRoleInfo", data, "post"),
  GetAssignRoleList: () => $http("UserRole/GetAssignRoleList"),
};

export const User = {
  LoginByWzSixItems: () => $http("User/LoginByWzSixItems", 0, "post"),
  Login: (data) => $http("User/Login", data, "post"),
  EmbedLogin: (data) => $http("User/EmbedLogin", data, "post"),
  LoginOut: (data) => $http("User/LoginOut", data, "post"),
  GetMenuTreeByToken: () => $http("User/GetMenuTreeByToken"),
  GetUserList: (data, page) => $http("User/GetUserList", data, "get", page),
  GetUserInfo: (data) => $http("User/GetUserInfo", data),
  SaveUserInfo: (data) => $http("User/SaveUserInfo", data, "post"),
  ResetPassword: (data) => $http("User/ResetPassword", data, "post"),
  DeleteUserInfo: (data) => $http("User/DeleteUserInfo", data, "post"),
  ChangerUserPwd: (data) => $http("User/ChangerUserPwd", data, "post"),
  LockUserInfo: (data) => $http("User/LockUserInfo", data, "post"),
  GetAssignUserListByRole: (data) =>
    $http("User/GetAssignUserListByRole", data),
  GetComplaintsAssignUserListByRole: (data) =>
    $http("User/GetComplaintsAssignUserListByRole", data),
  ExistsUsernameOrMobile: (data) =>
    $http("User/ExistsUsernameOrMobile", data, "post"),
  VerifyUserPasswordStrength: (data) =>
    $http("User/VerifyUserPasswordStrength", data, "post"), //验证密码的强弱  新接口
  GetCodeImage: (data) => $http(`User/GetCodeImage`, data, "get"), //获取图片验证码
  ValidatePhone: (data) => $http(`User/ValidatePhone`, data, "get"), //验证短信验证码
  GetVerificationCode: (data) => $http("User/GetVerificationCode", data), //获取验证码
  PlatformValidatePhone: (data) => $http("User/PlatformValidatePhone", data), //二次验证接口
  GetBondMechanismList: (data) => $http("User/GetBondMechanismList", data), //二次验证接口
};

export const Menu = {
  GetMenuTree: () => $http("Menu/GetMenuTree"),
  GetMenuInfo: (id) => $http("Menu/GetMenuInfo", id),
  SaveMenuInfo: (data) => $http("Menu/SaveMenuInfo", data, "post"),
  DeleteMenuInfo: (id) => $http("Menu/DeleteMenuInfo", id, "post"),
};

export const ProjectBillbord = {
  GetListByPages: (data, page) =>
    $http("ProjectBillbord/GetListByPages", data, "get", page),
  GetInfo: (data) => $http("ProjectBillbord/GetInfo", data),
  GetSupervisionBillbordListByPages: (data, page) =>
    $http(
      "ProjectBillbord/GetSupervisionBillbordListByPages",
      data,
      "get",
      page
    ),
};

export const OverestimateWarning = {
  GetListByPages: (data, page) =>
    $http("OverestimateWarning/GetListByPages", data, "get", page),
  GetInfo: (data) => $http("OverestimateWarning/GetInfo", data),
  GetUpdateProjectDetail: (data) =>
    $http("OverestimateWarning/GetUpdateProjectDetail", data),
};

export const FullPayment = {
  GetListByPage: (data, page) =>
    $http("FullPayment/GetListByPage", data, "get", page),
  GetDetailListByPage: (data, page) =>
    $http("FullPayment/GetDetailListByPage", data, "get", page),
  GetSalarListByPage: (data, page) =>
    $http("FullPayment/GetSalarListByPage", data, "get", page),
};

export const RealNameSystem = {
  GetProjectRealNameSystemListByPage: (data, page) =>
    $http(
      "RealNameSystem/GetProjectRealNameSystemListByPage",
      data,
      "get",
      page
    ),
  GetRosterListByPages: (data, page) =>
    $http("RealNameSystem/GetRosterListByPages", data, "get", page),
  GetAttendanceListByPages: (data, page) =>
    $http("RealNameSystem/GetAttendanceListByPages", data, "get", page),
  GetProjectAttendPayListByPid: (data, page) =>
    $http("RealNameSystem/GetProjectAttendPayListByPid", data, "get", page),
  GetAttendanceProjectPersonneListByPages: (data, page) =>
    $http(
      "RealNameSystem/GetAttendanceProjectPersonneListByPages",
      data,
      "get",
      page
    ),
  GetWagesListByPages: (data, page) =>
    $http("RealNameSystem/GetWagesListByPages", data, "get", page),
  GetProjectAttendPayMonthDetail: (data) =>
    $http("RealNameSystem/GetProjectAttendPayMonthDetail", data, "get"), //三方协议审核详情
  GetWagesProjectPersonnelListByPages: (data, page) =>
    $http(
      "RealNameSystem/GetWagesProjectPersonnelListByPages",
      data,
      "get",
      page
    ),
};

export const CompanyBond = {
  GovGetListByPages: (data, page) =>
    $http("CompanyBond/GovGetListByPages", data, "get", page),
  GetProjectManagerSafeguardDetail: (data) =>
    $http("CompanyBond/GetProjectManagerSafeguardDetail", data),
};

export const Dict = {
  GetAreasByWZ: () => $http("Dict/GetAreasByWZ"),
  GetAreasByParent: (code) => $http("Dict/GetAreasByParent", code),
  GetAreaList: (code) => $http("Dict/GetAreaList", code),
  GetAreaLevelList: () => $http("Dict/GetAreaLevelList"),
  GetSysDataList: (code) => $http("Dict/GetSysDataList", code),
  GetSysDataListByUserName: (code) =>
    $http("Dict/GetSysDataListByUserName", code),
  GetLevelList: (code) => $http("Dict/GetLevelList", code),
  GetLevelListByRoles: (role) => $http("Dict/GetLevelListByRoles", role),
  GetWorktypeDictList: () => $http("Dict/GetWorktypeDictList"),
  GetXldAreaDict: (code) => $http("Dict/GetXldAreaDict", code),
  GetXldAreaDictByWz: () => $http("Dict/GetXldAreaDictByWz"),
  GetAllAreaLevelList: () => $http("Dict/GetAllAreaLevelList"),
};

export const Project = {
  GetProjectListByPages: (data, page) =>
    $http("Project/GetProjectListByPages", data, "get", page),
  GetProjectConstructionDetailByWz: (data, page) =>
    $http("Project/GetProjectConstructionDetailByWz", data, "get", page),
  GetCooperativeCompanyList: (data, page) =>
    $http("Project/GetCooperativeCompanyList", data, "get", page),
  GetSixItemsByWz: (data, page) =>
    $http("Project/GetSixItemsByWz", data, "post", page),
  GetSixItemsToWzSalary: (data, page) =>
    $http("Project/GetSixItemsToWzSalary", data, "post", page),
  UpdateProject: (data) => $http("Project/UpdateProject", data, "post"),
  AuditNweProjects: (data) => $http("Project/AuditNweProjects", data, "post"),
  GetProjectList: (data) => $http("Project/GetProjectList", data, "get"),
  GetCheckProjectAuditInfoList: (data, page) =>
    $http("ProjectInfoPay/GetCheckProjectAuditInfoList", data, "get", page), //审核记录列表
  DoCheckProjectAuditInfo: (data) =>
    $http("ProjectInfoPay/DoCheckProjectAuditInfo", data, "post"), //审核通过或拒绝
  AuditProjectAttendPay: (data) =>
    $http("ProjectInfoPay/AuditProjectAttendPay", data, "post"), //审核通过或拒绝
  GetProjectAuditInfoList: (data, page) =>
    $http("ProjectInfoPay/GetProjectAuditInfoList", data, "get", page), //待审核列表
  GetProjectAuditRecordByPage: (data, page) =>
    $http("ProjectInfoPay/GetProjectAuditRecordByPage", data, "get", page), //待审核列表
  GetNewProjectOperateLogList: (data, page) =>
    $http("ProjectInfoPay/GetNewProjectOperateLogList", data, "get", page), //待审核列表
  GetProjectAuditInfoDetails: (data) =>
    $http("ProjectInfoPay/GetProjectAuditInfoDetails", data, "get"), //待审核详情
  GetProjectAttendPayAuditRecordDetail: (data) =>
    $http("ProjectInfoPay/GetProjectAttendPayAuditRecordDetail", data, "get"), //待审核详情
  GetProjectAttendPayAuditDetail: (data) =>
    $http("ProjectInfoPay/GetProjectAttendPayAuditDetail", data, "get"), //待审核详情
  GetTripartiteAgreementDetails: (data) =>
    $http("ProjectInfoPay/GetTripartiteAgreementDetails", data, "get"), //三方协议审核详情
};

export const FileAttributes = {
  Save: (data) => $http("FileAttributes/Save", data, "post"),
  GetList: (data, page) => $http("FileAttributes/GetList", data, "get", page),
  Delete: (data) => $http("FileAttributes/Delete", data, "post"),
};
export const ProjectBondAudit = {
  GetBondAuditListByPages: (data, page) =>
    $http("ProjectBondAudit/GetBondAuditListByPages", data, "get", page),
  MechanismAudit: (data) =>
    $http("ProjectBondAudit/MechanismAudit", data, "post"),
  AddVerificationProject: (data) =>
    $http("ProjectBondAudit/AddVerificationProject", data, "post"),
  Assign: (data) => $http("ProjectBondAudit/Assign", data, "post"),
  Audit: (data) => $http("ProjectBondAudit/Audit", data, "post"),
  Return: (data) => $http("ProjectBondAudit/Return", data, "post"),
  SubmitExamine: (data) =>
    $http("ProjectBondAudit/SubmitExamine", data, "post"),
  Conclude: (data) => $http("ProjectBondAudit/Conclude", data, "post"),
  Revoke: (data) => $http("ProjectBondAudit/Revoke", data, "post"),
  UpdateMechanism: (data) =>
    $http("ProjectBondAudit/UpdateMechanism", data, "post"),
  GetProjectMechanism: (data) =>
    $http("ProjectBondAudit/GetProjectMechanism", data, "get"),
  GetMechanismAuditDetail: (data) =>
    $http("ProjectBondAudit/GetMechanismAuditDetail", data, "get"),
  GetProjectBondAuditRecord: (data) =>
    $http("ProjectBondAudit/GetProjectBondAuditRecord", data, "get"),
  GetEarlyWarningCount: (data) =>
    $http("ProjectBondAudit/GetEarlyWarningCount", data, "get"),
  GetMechanismAuditRecord: (data) =>
    $http("ProjectBondAudit/GetMechanismAuditRecord", data, "get"),
  GetVerificationStatistics: (data) =>
    $http("ProjectBondAudit/GetVerificationStatistics", data, "get"),
};
export const SeparateAccounts = {
  GetListByPages: (data, page) =>
    $http("SeparateAccounts/GetListByPages", data, "get", page),
  GetLabourAccountInfo: (data) =>
    $http("SeparateAccounts/GetLabourAccountInfo", data),
  GetThreeAgreementInfo: (data) =>
    $http("SeparateAccounts/GetThreeAgreementInfo", data),
  GetBankAccountBalance: (data) =>
    $http("SeparateAccounts/GetBankAccountBalance", data),
  GetBankAccountStatus: (data) =>
    $http("SeparateAccounts/GetBankAccountStatus", data),
  GetProjectInfoAccount: (data, page) =>
    $http("SeparateAccounts/GetProjectInfoAccount", data, "get", page),
  GetProjectInfoAccountInfo: (data, page) =>
    $http("SeparateAccounts/GetProjectInfoAccountInfo", data, "get", page),
  GetLabourAccountsByCid: (data, page) =>
    $http("SeparateAccounts/GetLabourAccountsByCid", data, "get", page),
};

export const Monitoring = {
  GetList: (data, page) => $http("Monitoring/GetList", data, "get", page),
  Save: (data) => $http("Monitoring/Save", data, "post"),
  Delete: (data) => $http("Monitoring/Delete", data, "post"),
};

export const Complaint = {
  GetListByPages: (data, page) =>
    $http("Complaint/GetListByPages", data, "get", page),
  Get: (data) => $http("Complaint/Get", data),
};

export const ProjectComplaints = {
  GetProjectComplaintsList: (data, page) =>
    $http("ProjectComplaints/GetProjectComplaintsList", data, "get", page),
  GetRecordListByPages: (data, page) =>
    $http("ProjectComplaints/GetRecordListByPages", data, "get", page),
  GetSendRecordList: (data, page) =>
    $http("ProjectComplaints/GetSendRecordList", data, "get", page),
  GetProjectComplaintsInfo: (data) =>
    $http("ProjectComplaints/GetProjectComplaintsInfo", data),
  GetProjectComplaintsDetailed: (data) =>
    $http("ProjectComplaints/GetProjectComplaintsDetailed", data),
  GetComplaintsStatisticsByIndustryType: (data) =>
    $http("ProjectComplaints/GetComplaintsStatisticsByIndustryType", data),
  Assign: (data) => $http("ProjectComplaints/Assign", data, "post"),
  Complete: (data) => $http("ProjectComplaints/Complete", data, "post"),
  AuditComplaints: (data) =>
    $http("ProjectComplaints/AuditComplaints", data, "post"),
  Return: (data) => $http("ProjectComplaints/Return", data, "post"),
  UpdateComplaintsArea: (data) =>
    $http("ProjectComplaints/UpdateComplaintsArea", data, "post"),
  InsertGXComplaintInfo: (data) =>
    $http("ProjectComplaints/InsertGXComplaintInfo", data, "post"),
  SendRecord: (data) => $http("ProjectComplaints/SendRecord", data, "post"),
  GetProjectComplaintsStatistics: (data) =>
    $http("ProjectComplaints/GetProjectComplaintsStatistics", data, "get"),
};

export const ComplaintRecord = {
  GetListByPages: (data, page) =>
    $http("ComplaintRecord/GetListByPages", data, "get", page),
};

export const HomePage = {
  GetSixStatistics: (data) => $http("HomePage/GetSixStatistics", data),
  GetAgencyMatters: () => $http("HomePage/GetAgencyMatters"),
};

export const StatisticalTable = {
  TimeStatistics: (data) => $http("StatisticalTable/TimeStatistics", data),
  GetRegionalStatistics: (data) =>
    $http("StatisticalTable/GetRegionalStatistics", data),
  GetSixItemsChart: (data, page) =>
    $http("StatisticalTable/GetSixItemsChart", data, "get", page),
  GetServiceIndustryCategorySixItemsChart: (data, page) =>
    $http(
      "StatisticalTable/GetServiceIndustryCategorySixItemsChart",
      data,
      "get",
      page
    ),
  GetServiceIndustryCategorySixItemsStatistics: (data, page) =>
    $http(
      "StatisticalTable/GetServiceIndustryCategorySixItemsStatistics",
      data,
      "get",
      page
    ),
  GetSixItemsStatistics: (data, page) =>
    $http("StatisticalTable/GetSixItemsStatistics", data, "get", page),
  GetSixItemsTrendChart: (data) =>
    $http("StatisticalTable/GetSixItemsTrendChart", data),
  GetServiceIndustrtySubSixItemsTrendChart: (data) =>
    $http("StatisticalTable/GetServiceIndustrtySubSixItemsTrendChart", data),
};
export const UserArea = {
  GetAuthAreas: () => $http("UserArea/GetAuthAreas"),
  GetAuthStreets: (data) => $http("UserArea/GetAuthStreets", data),
  GetAssignAuthStreets: (data) => $http("UserArea/GetAssignAuthStreets", data),
};

export const ProjectNormFulfilHistoryRecord = {
  GetListByPages: (data, page) =>
    $http("ProjectNormFulfilHistoryRecord/GetListByPages", data, "get", page),
};

export const ProjectNormFulfilHistory = {
  GetListByPagers: (data, page) =>
    $http("ProjectNormFulfilHistory/GetListByPagers", data, "get", page),
  GetSmsRecord: (data, page) =>
    $http("ProjectNormFulfilHistory/GetSmsRecord", data, "get", page),
  Get: (data) => $http("ProjectNormFulfilHistory/Get", data),
  GetCompanyNormFulfilHistory: (data) =>
    $http("ProjectNormFulfilHistory/GetCompanyNormFulfilHistory", data),
  Assign: (data) => $http("ProjectNormFulfilHistory/Assign", data, "post"),
  Return: (data) => $http("ProjectNormFulfilHistory/Return", data, "post"),
  Complete: (data) => $http("ProjectNormFulfilHistory/Complete", data, "post"),
  SendSmsRemind: (data) =>
    $http("ProjectNormFulfilHistory/SendSmsRemind", data, "post"),
};
export const SalaryInfor = {
  GetSalarListByPage: (data, page) =>
    $http("SalaryInfor/GetSalarListByPage", data, "get", page),
  GetSalarInforDetailList: (data, page) =>
    $http("SalaryInfor/GetSalarInforDetailList", data, "get", page),
};

export const Download = {
  ExportGetSixItemsByWz: (data, page) =>
    $http("Export/ExportGetSixItemsByWz", data, "post", page),
};

//CreditAppraise
export const CreditAppraiseApi = {
  //信用评价列表
  GetCreditAppraiseList: (data, page) =>
    $http("CreditAppraise/GetCreditAppraiseList", data, "get", page),

  //信用评价信息
  GetCreditAppraise: (data) =>
    $http("CreditAppraise/GetCreditAppraise", data, "get"),

  //信用评价按照辖区统计
  GetCountyStatisticsList: (data, page) =>
    $http("CreditAppraise/GetCountyStatisticsList", data, "get", page),

  //信用评价按照企业统计
  GetCompanyStatisticsList: (data, page) =>
    $http("CreditAppraise/GetCompanyStatisticsList", data, "get", page),

  //保存信用评价
  SaveCreditAppraise: (data) =>
    $http("CreditAppraise/SaveCreditAppraise", data, "post"),

  //项目下拉框列表
  GetProjectSelectList: (data) =>
    $http("CreditAppraise/GetProjectSelectList", data, "get"),
};
