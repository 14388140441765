<template>
    <div>
        <div id="drag" :class="{'valid-success': valid}">
            <div class="drag_bg" :style="{width: currentX + 'px'}"></div>
            <div class="drag_text">{{ valid ? '验证成功' : '请按住滑块，拖动到最右边' }}</div>
            <div class="handler handler_bg" :style="{left: currentX + 'px'}"
                 @mousedown="onInit" @touchstart="onInit"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "drag",
        data() {
            return {
                draging: false,
                valid: this.mode,
                startX: 0,
                endX: 0,
                currentX: 0,
            }
        },
        model: {
            prop: "mode"
        },
        props: {
            mode: {
                type: Boolean
            },
        },
        methods: {
            success(val) {
                window.removeEventListener('touchmove', this.onDragMove)
                window.removeEventListener('touchend', this.onDragEnd)

                window.removeEventListener('mousemove', this.onDragMove)
                window.removeEventListener('mouseup', this.onDragEnd)
                this.valid = val;
                this.$emit("input", val)
            },
            onInit(event) {
                event.preventDefault()
                if (!this.valid) {
                    this.onStart(event)
                    window.addEventListener('touchmove', this.onDragMove)
                    window.addEventListener('touchend', this.onDragEnd)

                    window.addEventListener('mousemove', this.onDragMove)
                    window.addEventListener('mouseup', this.onDragEnd)
                }
            },
            onStart(event) {
                this.draging = true;
                let drag = document.getElementById("drag");
                let handler = drag.getElementsByClassName("handler")[0];
                let eventX = event.type == 'touchstart' ? event.touches[0].clientX : event.clientX;
                this.startX = eventX - handler.offsetLeft;
                this.endX = drag.offsetWidth - handler.offsetWidth;
            },
            onDragMove(event) {
                if (this.draging) {
                    let eventX = event.type == 'touchmove' ? event.touches[0].clientX : event.clientX;
                    let currnetX = eventX - this.startX;
                    if (currnetX > 0 && currnetX <= this.endX) {
                        this.currentX = currnetX
                    } else if (currnetX > this.endX) {
                        this.currentX = this.endX
                        this.success(true)
                    }
                }
            },
            onDragEnd(event) {
                if (this.draging) {
                    setTimeout(() => {
                        this.draging = false
                    }, 0)
                    let eventX = event.type == 'touchend' ? event.touches[0].clientX : event.clientX;
                    let currentX = eventX - this.startX;
                    if (currentX < this.endX) {
                        this.currentX = 0
                    }
                    window.removeEventListener('touchmove', this.onDragMove)
                    window.removeEventListener('touchend', this.onDragEnd)

                    window.removeEventListener('mousemove', this.onDragMove)
                    window.removeEventListener('mouseup', this.onDragEnd)
                }
            }
        }
    }
</script>

<style scoped>
    #drag {
        position: relative;
        background-color: #e6e6e6;
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
    }

    #drag .handler {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 50px;
        height: 40px;
        border: 1px solid #ccc;
        cursor: move;
        box-sizing: border-box;
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        -o-user-select: none;
        -ms-user-select: none;
    }

    .handler_bg {
        background: #fff url(../../assets/images/arrow.png) no-repeat center;
    }

    #drag .drag_bg {
        background-color: #7ac23c;
        height: 40px;
        width: 0px;
    }

    #drag .drag_text {
        position: absolute;
        top: 0px;
        font-size: 12px;
        width: 100%;
    }

    #drag.valid-success .drag_text {
        color: #fff;
    }

    #drag.valid-success .handler_bg {
        background: #fff url(../../assets/images/right.png) no-repeat center;
    }
</style>
