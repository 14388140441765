import Vue from 'vue';
import Vuex from 'vuex';
import vuexModules from './modules';
import createVuexAlong from 'vuex-along'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: vuexModules,
    plugins: [createVuexAlong({
        name: 'MEMU_DATA',
        session: {
            list: ['UserModule', 'MenuModule']
        },
        justSession: true
    })]
})
