<template>
    <ul class="dialog-tab">
        <template v-for="(item, index) in tabs">
            <li :key="index" :class="{active: item.value == value}" @click="handle(item.value)">{{ item.label }}</li>
        </template>
    </ul>
</template>

<script>
    export default {
        name: "dialogTab",
        data() {
            return {
                value: this.mode
            }
        },
        watch: {
            mode(val) {
                this.value = val;
            }
        },
        model: {
            prop: "mode"
        },
        props: {
            tabs: {
                type: Array,
                required: true
            },
            mode: {
                type: [Number, String]
            },
        },
        methods: {
            async handle(value) {
                this.$emit('input', value)
                this.$emit('tab-click', value)
            }
        }
    }
</script>

<style scoped lang="scss">
    .dialog-tab {
        display: flex;
        padding-bottom: 15px;
        border-bottom: 1px solid #DBDBDB;;

        li {
            cursor: pointer;
            font-size: 20px;
            line-height: 30px;
            margin: 0 20px;
            padding-bottom: 10px;
            box-sizing: border-box;
        }

        li.active {
            color: #2A76E4;
            border-bottom: 2px solid #2A76E4;
        }
    }
</style>
