const staticRoutes = [{
    path: "/",
    redirect: "/login",
    component: () => import("../pages/login/index.vue"),
    children: [{
        path: '/login',
        name: "login",
        component: () => import("../pages/login/login.vue")
    }, {
        path: '/resetPwd',
        name: 'resetPwd',
        component: () => import("../pages/login/reset/resetPwd.vue")
    }, {
        path: '/download',
        name: "download",
        component: () => import("../pages/login/download.vue")
    }]
}, {
    path: "/salaryPayment",
    name: 'salaryPayment',
    component: () => import("../pages/salaryPayment/index.vue")
}, {
    path: "/salaryPayment/detail",
    name: 'ProjectSix',
    component: () => import("../pages/salaryPayment/ProjectSix.vue")
}, {
    path: "/complaint/index",
    redirect: "/",
    name: "",
    component: () => import("../pages/phone/dashboard.vue"),
    children: [{
        path: "/",
        meta: {
            title: "我为“无欠薪温州”献力"
        },
        component: () => import("../pages/phone/index.vue")
    }, {
        path: "/complaint/select",
        meta: {
            title: "我为“无欠薪温州”献力"
        },
        component: () => import("../pages/phone/select.vue")
    }, {
        path: "/complaint/project",
        meta: {
            title: "我为“无欠薪温州”献力"
        },
        component: () => import("../pages/phone/project.vue")
    }, {
        path: "/complaint/form",
        meta: {
            title: "我为“无欠薪温州”献力"
        },
        component: () => import("../pages/phone/submit.vue")
    }, {
        path: "/complaint/censorate",
        meta: {
            title: "我为“无欠薪温州”献力"
        },
        component: () => import("../pages/phone/censorate.vue")
    }, {
        path: "/complaint/404",
        meta: {
            title: "404"
        },
        component: () => import("../pages/phone/404.vue")
    }, {
        path: "/complaint/success",
        meta: {
            title: "我为“无欠薪温州”献力"
        },
        component: () => import("../pages/phone/success.vue")
    }, {
        path: "/complaint/noNet",
        meta: {
            title: "我为“无欠薪温州”献力"
        },
        component: () => import("../pages/phone/noNet.vue")
    }]
}];
export default staticRoutes;
