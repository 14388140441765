<template>
    <el-select :disabled="disabled" clearable v-model="val" :placeholder="holder" :size="size" @change="select"
               :loading="loading">
        <el-option v-for="(item,index) in list" :key="index" :label="item.dicValue"
                   :value="code ? item.dicNo : item.dicValue"></el-option>
    </el-select>
</template>

<script>
    import {Dict} from "../../util/api"

    export default {
        name: "",
        data() {
            return {
                val: this.mode,
                list: [],
                loading: false
            }
        },
        watch: {
            mode(val) {
                this.val = val;
            },
            area(code) {
                this.init();
                if (!code) {
                    this.select(null);
                }
            }
        },
        model: {
            prop: "mode"
        },
        props: {
            mode: {
                type: [Number, String]
            },
            holder: {
                type: String
            },
            size: {
                type: String,
                default: "small"
            },
            code: {
                type: Boolean,
                default: true
            },
            disabled: {
                type: Boolean,
                default: false
            },

        },
        mounted() {
            this.init()
        },
        methods: {
            async init() {
                this.loading = true;
                let roleId = this.$store.state.UserModule.userInfo.roleLevel;
                let res = await Dict.GetLevelListByRoles(roleId);
                this.loading = false;
                this.list = res || [];

            },
            select(val) {
                let obj = this.list.find(item => {
                    // let current = this.code ? item.dicNo : item.dicValue;
                    // return current == val;
                    if(item.dicNo==val){
                        return item
                    }
                }) || {};
                this.$emit("input", val);
                this.$emit("change", val, obj);
            }
        }
    }
</script>

<style scoped>

</style>
