import axios from "axios";
import store from "@/store";
import Msg from "@/lib/message";
import baseUrl from "./env";
import Qs from "qs";

//const BASE_URL = process.env.VUE_APP_BASE_URL;
const $http = (url, data, type, page) => {
    let token = store.state.UserModule.token
    let options = {
        baseURL: baseUrl,
        timeout: 100000,
        url: url,
        method: type || "get",
        paramsSerializer(params) {
            return Qs.stringify(params, {
                arrayFormat: 'repeat',
                allowDots: true
            })
        }
    };
    if (token) {
        options.headers = {
            'X-Token': token
        };
    }
    let params = {
        Parameter: data,
        Paging: page
    };
    if (options.method == "post") {
        options.data = params;
    } else {
        options.params = params;
    }
    return new Promise(resolve => {
        axios(options).then(res => {
            let {status, data} = res;
            if (status == 200) {
                if (data.resultType == 1) {
                    if (page) {
                        let list = data.data || [];
                        let paging = data.paging || {};
                        resolve({data: list, page: paging})
                    } else {
                        if (typeof (data.data) == 'boolean') {
                            resolve(data.data)
                        } else if (typeof (data.data) == 'number') {
                            resolve(data.data)
                        } else {
                            resolve(data.data || true)
                        }
                    }
                } else if (data.resultType == 2) {
                    Msg.error(data.message);
                    resolve(false)
                } else if (data.resultType == 10000) {
                    sessionStorage.clear();
                    if (store.state.UserModule.isSalaryPayment) {
                        Msg.error("请重试");
                        store.dispatch('UserModule/autoLogin')
                    } else {
                        Msg.error(data.message);
                        window.location.href = '/'
                    }
                } else {
                    Msg.error('error' + `code: ${data.code}`);
                    resolve(false)
                }
            } else {
                resolve(false)
            }
        }).catch(() => {
            Msg.error(url + '接口报错!');
            // resolve(false)
        })

    })
};

export default $http
