<template>
    <el-image v-if="type == 4" :src="src" :preview-src-list="imgList" ref="pic" class="info-pic"></el-image>
    <img v-else :src="iconSrc" class="info-pic" @click="openUrl">
</template>

<script>
    export default {
        name: "VFile",
        data() {
            return {}
        },
        computed: {
            type() {
                let src = this.src.toLowerCase()
                if (this.isWord(src)) {
                    return 1
                } else if (this.isExcel(src)) {
                    return 2
                } else if (this.isPdf(src)) {
                    return 3
                } else {
                    return 4
                }
            },
            iconSrc() {
                switch (this.type) {
                    case 1:
                        return require('../../assets/img/word-icon.png')
                    case 2:
                        return require('../../assets/img/excel-icon.png')
                    case 3:
                        return require('../../assets/img/pdf-icon.png')
                    default:
                        return '';
                }
            },
            imgList() {
                let list = [];
                this.list.forEach(val => {
                    if (this.isImg(val)) {
                        list.push(val)
                    }
                });
                return list
            }
        },
        props: {
            src: {
                type: String,
                required: true
            },
            list: {
                type: Array,
                default: function () {
                    return []
                }
            }
        },
        methods: {
            show() {
                if (this.type == 4) {
                    this.$refs.pic.showViewer = true
                } else {
                    this.openUrl()
                }
            },
            openUrl() {
                window.open(this.src, "_blank")
            },
            isPdf(src) {
                return src.includes('.pdf')
            },
            isWord(src) {
                return src.includes('.doc') || src.includes('.docx')
            },
            isExcel(src) {
                return src.includes('.xls') || src.includes('.xlsx')
            },
            isImg(src) {
                return !this.isPdf(src) && !this.isWord(src) && !this.isExcel(src)
            }
        }
    }
</script>

<style scoped>
    .info-pic {

        cursor: pointer;
        margin-right: 10px;
        margin-bottom: 10px;
    }
</style>
