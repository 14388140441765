import {User} from "../../util/api";
import router from '../../router'

const state = {
    Menus: [],
    OpenTabs: [{
        label: '首页',
        name: '/home',
        cache: 'home'
    }],
    ActiveIndex: '/home'
}

const mutations = {
    setMenus(state, data) {
        state.Menus = data || [];
    },
    setActiveIndex(state, index) {
        state.ActiveIndex = index;
    },
    addNavTab(state, tab) {
        let index = state.OpenTabs.findIndex(val => val.name == tab.name)
        if (index == -1) {
            state.OpenTabs.push(tab);
        }
    },
    removeNavTab(state, name) {
        let index = state.OpenTabs.findIndex(val => val.name == name);
        let tab = state.OpenTabs[index - 1];
        state.OpenTabs.splice(index, 1)
        if (state.ActiveIndex == name) {
            router.push(tab.name)
        }
    },
    closeAllTab(state) {
        state.OpenTabs = [{
            label: '首页',
            name: '/home'
        }];
        state.ActiveIndex = '/home';
    },
    closeOtherTab(state, data) {
        if (data.name == '/home') {
            state.OpenTabs = [{
                label: '首页',
                name: '/home'
            }];
        } else {
            state.OpenTabs = [{
                label: '首页',
                name: '/home'
            }, data];
        }

    }
}

const actions = {
    async getMenus({commit}) {
        let res = await User.GetMenuTreeByToken()
        commit('setMenus', res)
        return res
    }
}

export default {
    namespaced: true,
    actions,
    state,
    mutations
}
