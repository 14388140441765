<template>
    <el-upload :action="baseUrl + path" :headers="headers" list-type="picture-card" :file-list="filelist"
               :before-upload="beforeUpload" :on-success="uploadSuccess" :on-error="onError"
               :class="{'attr-upload': !!src}" :disabled="uploading">
        <i slot="default" class="el-icon-plus"></i>
        <template #file="{file}" v-if="!uploading">
            <v-file class="el-upload-list__item-thumbnail" :src="file.url" :list="[file.url]" ref="vfile"></v-file>
            <label class="el-upload-list__item-status-label">
                <i class="el-icon-upload-success el-icon-check"></i>
            </label>
            <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview">
                  <i class="el-icon-zoom-in" @click="$refs.vfile.show()"></i>
                </span>
                <span class="el-upload-list__item-delete">
                    <i class="el-icon-download" @click="handleDownload"></i>
                </span>
                <span class="el-upload-list__item-delete">
                    <i class="el-icon-delete" @click="handleDel"></i>
                </span>
              </span>
        </template>
    </el-upload>
</template>

<script>
    import VFile from "./VFile";

    //const baseUrl = process.env.VUE_APP_BASE_URL;
    import baseUrl from "@/util/env";

    export default {
        name: "UploadFile",
        data() {
            return {
                uploading: false,
                baseUrl: baseUrl
            }
        },
        components: {
            VFile
        },
        computed: {
            filelist() {
                if (!this.src) {
                    return []
                } else {
                    return [{
                        name: '',
                        url: this.src
                    }]
                }

            },
            headers() {
                let token = this.$store.state.UserModule.token
                return {
                    'X-Token': token
                }
            },
        },
        props: {
            src: {
                //type: String,
                required: true
            },
            accept: {
                type: Array,
                required: true
            },
            path: {
                type: String,
                required: true
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
            },
            beforeUpload(file) {
                if (!file) {
                    return false
                }
                this.uploading = true;
                let size = (file.size / 1024 / 1024) <= 25;
                if (!size) {
                    this.$message.error(`文件大于25M!`)
                    this.uploading = false;
                    return false
                }
                let nameArr = file.name.split(".");
                let suffix = nameArr[nameArr.length - 1];
                let types = this.accept;
                if (types.includes('.' + suffix.toLowerCase())) {
                    return true
                } else {
                    this.$message.error(`请上传${types.join(',')}格式的文件!`)
                    this.uploading = false;
                    return false
                }
            },
            uploadSuccess(res) {
                if (res) {
                    this.$emit('success', res.data)
                } else {
                    this.$message.error(res.Message)
                }
                this.uploading = false;
            },
            onError() {
                this.uploading = false;
            },
            handleDownload() {
                window.open(this.src, "_blank")
            },
            handleDel() {
                this.$emit('success', '')
            }
        }
    }
</script>

<style scoped lang="scss">
    .attr-upload {
        /*::v-deep.el-upload:nth-child(2) {
            display: none !important;
        }*/
    }
</style>
