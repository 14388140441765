<template>
    <div class="upload-list">
        <template v-for="(item, index) in filsMax">
            <upload-file :src="srcList[index]" :key="index" :accept="accept" :path="path"
                         @success="val => success(val, index)" disabled>
            </upload-file>
        </template>
    </div>
</template>

<script>
    import UploadFile from "./UploadFile";

    export default {
        name: "UploadList",
        data() {
            return {
                params: this.mode
            }
        },
        components: {
            UploadFile
        },
        computed: {
            srcList() {
                if (this.prop.length > 0) {
                    let list = []
                    this.prop.forEach(val => {
                        if (this.mode[val]) {
                            list.push(this.mode[val])
                        }
                    })
                    return list
                }
                let str = this.mode;
                if (this.isString) {
                    let isStr = typeof (str) == 'string'
                    return (str && isStr) ? str.split(',') : []
                } else {
                    return str || []
                }
            },
            filsMax() {
                let propLength = this.prop.length;
                let max = propLength > 0 ? propLength : this.max
                let srcNum = this.srcList.length;
                return srcNum >= max ? max : srcNum + 1
            }
        },
        model: {
            prop: 'mode',
            event: 'input'
        },
        watch: {
            mode: {
                deep: true,
                handler(val) {
                    this.params = val;
                }
            }
        },
        props: {
            max: {
                type: Number,
                default: 1
            },
            isString: {
                type: Boolean,
                default: false
            },
            mode: {
                type: [Object, String, Array]
            },
            prop: {
                type: Array,
                default: function () {
                    return []
                }
            },
            accept: {
                type: Array,
                default: function () {
                    return ['.doc', '.png', '.docx', '.pdf', '.xls', '.xlsx', '.jpg', '.jpeg']
                }
            },
            path: {
                type: String,
                required: true
            }

        },
        methods: {
            success(url, index) {
                if (this.prop.length > 0) {
                    let key = this.prop[index];
                    this.params[key] = url;
                    return this.resetData();
                }
                if (this.isString) {
                    let attrs = this.params;
                    let list = attrs ? attrs.split(",") : [];
                    if (url) {
                        list[index] = url;
                    } else {
                        list.splice(index, 1)
                    }
                    this.params = list.join(',')
                    this.$emit('input', this.params)
                } else {
                    let list = this.params;
                    if (url) {
                        list[index] = url;
                    } else {
                        list.splice(index, 1)
                    }
                    this.params = list;
                    this.$emit('input', [...this.params])
                }
            },
            resetData() {
                let list = []
                this.prop.forEach(val => {
                    if (this.params[val]) {
                        list.push(this.params[val])
                        this.params[val] = '';
                    }
                })
                list.forEach((val, index) => {
                    this.params[this.prop[index]] = val;
                })
                this.$emit('input', {...this.params})
            }
        }
    }
</script>

<style scoped lang="scss">
    .upload-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        overflow: hidden;

        & > div {
            margin-right: 15px;
            height: 160px;
        }
    }
</style>
