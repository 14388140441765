import Vue from "vue";
import vueRouter from "vue-router";
import nProgress from "nprogress";
import "nprogress/nprogress.css";
import staticRoutes from "./staticRoutes";
import store from "../store";

Vue.use(vueRouter);

const router = new vueRouter({
    mode: "history",
    routes: staticRoutes
});
const routesMatch = menu => {
    let home = {
        path: "/home",
        component: () =>
            import ("../pages/home/index.vue"),
        children: [{
            path: "/",
            name: "home",
            meta: {
                title: "首页",
                parent_title: ""
            },
            component: () =>
                import ("../pages/home/home.vue")
        }]
    };
    return new Promise(resolve => {
        let match = data => {
            data.forEach(val => {
                if (val.item && val.item.length > 0) {
                    return match(val.item);
                } else {
                    let item = {
                        path: val.vuePath,
                        meta: {
                            title: val.name,
                            parent_title: val.parentName,
                            cache: val.vueName
                        },
                        component: () =>
                            import ("../pages/" + val.vueFileName)
                    };
                    home.children.push(item);
                }
            });
        };
        match(menu);
        resolve([home]);
    });
};

const WHIT_LIST = [
    "/",
    "/login",
    "/resetPwd",
    "/salaryPayment",
    "/salaryPayment/detail",
    "/download",
    "/complaint/index",
    "/complaint/select",
    "/complaint/project",
    "/complaint/404",
    "/complaint/form",
    "/complaint/censorate",
    "/complaint/success",
    "/complaint/noNet"
];
let ready = false;

router.beforeEach(async (to, from, next) => {
    nProgress.start();
    if (WHIT_LIST.includes(to.path)) {
        if (to.meta.title) {
            document.title = to.meta.title;
        }
        return next();
    }
    let token = store.state.UserModule.token;
    let menus = store.state.MenuModule.Menus;
    if (token) {
        if (ready) {
            return next();
        } else {
            routesMatch(menus).then(list => {
                router.addRoutes(list);
                ready = true;
                next(to.path);
            });
        }
    } else {
        alert("登录失效!");
        nProgress.done();
        return next("/");
    }
});

router.afterEach(() => {
    nProgress.done();
});

export default router;
