<template>
    <el-select
        :clearable="clearable"
        :filterable="filterable"
        :multiple="multiple"
        :disabled="disabled"
        v-model="val"
        :value-key="value || valueKey"
        :placeholder="holder"
        :remote-method="remoteMethod"
        :size="size"
        collapse-tags
        class="async-select"
        :remote="isRemote"
        @change="select">
        <slot name="option"></slot>
        <el-option
            v-for="(item,index) in list"
            :key="index" :label="isItem ? item : item[label]"
            :value="isItem || !value ? item : item[value]">
        </el-option>
    </el-select>
</template>

<script>
    export default {
        name: "",
        data() {
            return {
                val: this.mode,
                list: []
            }
        },
        watch: {
            mode(val) {
                this.val = val;
            },
            params: {
                deep: true,
                handler() {
                    this.select('');
                    this.init()
                }
            }

        },
        model: {
            prop: "mode"
        },
        props: {
            mode: {
                type: [Number, String, Object, Array]
            },
            holder: {
                type: String
            },
            label: {
                type: String,
                default: "id"
            },
            value: {
                type: [String, Boolean],
                default: "id"
            },
            valueKey: {
                type: [String, Boolean],
                default: "id"
            },
            isItem: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: "small"
            },
            filterable: {
                type: Boolean,
                default: true
            },
            clearable: {
                type: Boolean,
                default: true
            },
            multiple: {
                type: Boolean,
                default: false
            },
            api: {
                type: Function,
                required: true
            },
            params: {
                type: [Object, String, Number],
                default: function () {
                    return {}
                }
            },
            setDefault: {
                type: Boolean,
                default: false
            },
            isRequire: {
                type: [String, Boolean],
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            paramKey:{
                type: String,
            },
            isRemote:{
                type: Boolean,
                default: false
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                if (this.disabled) {
                    return false
                }
                let res = await this.api(this.params);
                if (res) {
                    this.list = res || [];
                }

                if (this.mode) {
                    this.select(this.mode);
                }

                if (this.setDefault) {
                    let item = res[0] || {};
                    this.select(this.value ? item[this.value] : item);
                }
            },
            select(val) {
                let obj = {};
                if (val) {
                    if (!this.value) {
                        obj = this.list.find(item => item[this.valueKey] == val[this.valueKey]) || {};
                    } else {
                        obj = this.list.find(item => item[this.value] == val) || {};
                    }
                }
                val = this.value ? val : obj;
                this.$emit("input", val, obj);
                this.$emit("change", val, obj);
            },
            async remoteMethod(query) {
                if(!this.isRemote) {
                    return false;
                }
                const _this=this;
                setTimeout(async () => {
                    let res = await this.api({[this.paramKey]:query});
                    if (res) {
                        _this.list = res || [];
                    }
                },100)
            }
        }
    }
</script>

<style scoped>
    .async-select {
        width: 200px;
    }
</style>
