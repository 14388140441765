import Vue from 'vue';

//企业类型
Vue.filter("COMPANY_TYPE", function (val) {
    switch (parseInt(val)) {
        case 1:
            return "建设单位";
        case 2:
            return "施工单位";
        case 3:
            return "劳务公司";
        case 4:
            return "设计单位";
        case 10:
            return "制造业";
        case 99:
            return "其他企业";
        default:
            break;
    }
})

//投资性质
Vue.filter("INVESTMENT_NATURE", function () {
    return '--'
    /*switch(parseInt(val)) {
        case 1:
            return "非政府投资";
        case 2:

            return "政府投资";
        default:
            break;
    }*/
})

//有 无
Vue.filter("HAVE_STATUS", function (val) {
    switch (parseInt(val)) {
        case 1:
            return "无";
        case 2:
            return "有";
        case 3:
            return "有";
        default:
            break;
    }
})

//落实
Vue.filter("WORKABLE_STATUS", function (val) {
    return val != 1 ? "已落实" : "未落实"
    /*switch(parseInt(val)) {
        case 1:
            return "未落实";
        case 2:
            return "已处理";
        case 3:
            return "已落实";
        default:
            break;
    }*/
})

//缴纳状态
Vue.filter("PAY_STATUS", function (val) {
    switch (parseInt(val)) {
        case 1:
            return "未缴";
        case 2:
            return "办理中";
        case 3:
            return "在缴";
        case 4:
            return "免缴";
        default:
            break;
    }
})

//上传状况
Vue.filter("UPLOAD_STATUS", function (val) {
    switch (parseInt(val)) {
        case 1:
            return "无"
        case 2:
            return "部分上传"
        case 3:
            return "全部上传"
    }
})

//缴纳形式
Vue.filter("PAY_TYPE", function (val) {
    switch (parseInt(val)) {
        case 1:
            return "保函";
        case 2:
            return "现金";
        case 3:
            return "保险";
        default:
            break;
    }
})

//更新状况
Vue.filter("UPDATE_STATE", function (val) {
    switch (parseInt(val)) {
        case 1:
            return "未更新"
        case 2:
            return "已处理"
        case 3:
            return "已更新"
    }
})

//颜色
Vue.filter("COLORS", function (val) {
    switch (parseInt(val)) {
        case 1:
            return {color: '#E83E3E'}
        case 2:
            return {color: '#FF8400'}
        case 3:
            return {}
    }
})

Vue.filter("FILE_LIST", function (val, index) {
    let list = [];
    let {file1, file2, file3, file4, file5} = val;
    file1 && !file1.toLowerCase().includes('.pdf') && list.push(file1)
    file2 && !file1.toLowerCase().includes('.pdf') && list.push(file2)
    file3 && !file1.toLowerCase().includes('.pdf') && list.push(file3)
    file4 && !file1.toLowerCase().includes('.pdf') && list.push(file4)
    file5 && !file1.toLowerCase().includes('.pdf') && list.push(file5)
    let right = list.splice(index - 1)
    return [].concat(right, list)
})
Vue.filter('formatDate', (time, type) => {
    const getHandledValue = num => {
        return num < 10 ? '0' + num : num
    }
    let d = time ? new Date(time.replace(/-/g, '/')) : ""
    if (d == '') {
        return ''
    }
    let year = d.getFullYear()
    let month = getHandledValue(d.getMonth() + 1)
    let date = getHandledValue(d.getDate())
    let hours = getHandledValue(d.getHours())
    let minutes = getHandledValue(d.getMinutes())
    let second = getHandledValue(d.getSeconds())
    let resStr = ''
    if (type) {
        resStr = year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + second
    } else {
        resStr = year + '-' + month + '-' + date
    }
    return resStr
});
