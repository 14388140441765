import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import "./components"
import "./lib/filters"

import "./lib/mint"

import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/element-variables.scss'
import baseUrl from "@/util/env";
Vue.config.productionTip = false
//Vue.prototype.$BASE_URL = process.env.VUE_APP_BASE_URL;
Vue.prototype.$BASE_URL = baseUrl;

Vue.use(ElementUI, {size: 'small'})
Vue.prototype.userInfo = store.state.UserModule.userInfo
Vue.prototype.regular = '/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*`~()-+=]+$)(?![0-9\\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*`~()-+=]{8,20}$/'
window.vm = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
