import Vue from "vue";
import drag from "./common/drag.vue";
import VCol from "./layout/VCol.vue";
import ExportFile from "./common/ExportFile.vue";
import Table from "./form/Table.vue";
import AsyncSelect from "./form/AsyncSelect.vue";
import Dictionary from "./form/Dictionary.vue";
import Street from "./form/Street.vue";
import UploadButton from "./form/UploadButton";
import dialogTab from "./common/dialogTab.vue";
import InfoNull from "./common/InfoNull.vue";
import SelectArea from "./form/SelectArea.vue";
import ImgPdf from "./common/ImgPdf.vue";
import ImgCode from "./common/ImgCode.vue";
import SendMsg from "./common/SendMsg.vue";
import UploadList from "../components/upd/UploadList";
import VFile from "../components/upd/VFile";
const component = {
  install(Vue) {
    Vue.component("v-drag", drag);
    Vue.component("v-table", Table);
    Vue.component("v-col", VCol);
    Vue.component("async-select", AsyncSelect);
    Vue.component("dictionary", Dictionary);
    Vue.component("street", Street);
    Vue.component("export-file", ExportFile);
    Vue.component("dialog-tab", dialogTab);
    Vue.component("upload-button", UploadButton);
    Vue.component("info-null", InfoNull);
    Vue.component("select-area", SelectArea);
    Vue.component("img-pdf", ImgPdf);
    Vue.component("img-code", ImgCode);
    Vue.component("send-msg", SendMsg);
    Vue.component("UploadList", UploadList);
    Vue.component("VFile", VFile);
  },
};

Vue.use(component);
