<template>
    <el-select :disabled="disabled" clearable v-model="val" :placeholder="holder" :size="size" @change="select"
               :loading="loading">
        <template v-if="dic == 'area'">
            <el-option v-for="(item,index) in list" :key="index" :label="item.name"
                       :value="code ? item.code : item.name"></el-option>
        </template>
        <template v-else>
            <el-option v-for="(item, index) in list" :key="index" :label="item.dataKey"
                       :value="code ? item.dataValue : item.dataKey"></el-option>
        </template>
    </el-select>
</template>

<script>
    import {Company, Dict} from "../../util/api"

    export default {
        name: "",
        data() {
            return {
                val: this.mode,
                list: [],
                loading: false
            }
        },
        watch: {
            mode(val) {
                this.val = val;
            },
            area(code) {
                this.init();
                if (!code) {
                    this.select(null);
                }
            }
        },
        model: {
            prop: "mode"
        },
        props: {
            mode: {
                type: [Number, String]
            },
            holder: {
                type: String
            },
            dic: {
                required: true,
                type: String
            },
            size: {
                type: String,
                default: "small"
            },
            code: {
                type: Boolean,
                default: true
            },
            area: {
                type: [Number, String, Boolean],
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            async init() {
                this.loading = true;
                let res = this.dic == "area" ? await Company.GetAreaList(this.area) : await Dict.GetSysDataList(this.dic);
                this.loading = false;
                this.list = res || [];

            },
            select(val) {
                /*let obj = this.list.find(item => {
                    let current = this.code ? item.dataValue : item.dataKey;
                    return current == val;
                }) || {};*/
                let obj = this.list.find(item => {
                    let current = this.code ? item.code : item.name;
                    return current == val;
                }) || {};
                this.$emit("input", val);
                this.$emit("change", val,obj);
            }
        }
    }
</script>

<style scoped>

</style>
