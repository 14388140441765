<template>
    <el-button :type="type"
               :disabled="disabled || busy || (seconds < time)"
               class="send-code" @click="sendCode">
        {{ sendText }}
    </el-button>
</template>

<script>
    import {User} from "../../util/api";

    export default {
        name: "SendMsg",
        data() {
            return {
                sendText: '获取验证码',
                busy: false,
                seconds: this.time
            }
        },
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            params: {
                required: true
            },
            time: {
                type: Number,
                default: 60
            },
            type: {
                type: String,
                default: 'warning'
            }
        },
        methods: {
            async sendCode() {
                if (this.busy) {
                    return
                }
                this.busy = true;
                let userInfo = this.$store.state.UserModule.userInfo || {}
                let params = {
                    ...this.params,
                    user_type: userInfo.usertype
                }

                let res = await User.GetVerificationCode(params);
                if (res) {
                    this.$message.success('验证码已发送!')
                    this.interval();
                    this.$emit('send', true, res)
                } else {
                    this.$emit('send', false)
                    this.$message.error(res.Message);
                    this.busy = false;
                    this.sendText = "获取验证码"
                }
            },
            interval() {
                if (this.seconds == 0) {
                    this.seconds = this.time;
                    this.busy = false;
                    this.sendText = "获取验证码";
                    return clearInterval(this.timer);
                }
                this.seconds--;
                this.sendText = this.seconds + "s";
                this.timer = setInterval(() => {
                    clearInterval(this.timer);
                    this.interval()
                }, 1000)
            }
        }
    }
</script>

<style scoped>
    .send-code {
        margin-left: 10px;
        width: 100px;
    }
</style>
