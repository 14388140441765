<template>
    <img :src="imgSrc" @click="init" class="img-code">
</template>

<script>
    import {User} from "../../util/api";

    export default {
        name: "ImgCode",
        data() {
            return {
                key: 0,
                imgSrc: '',
                codeKey: this.mode
            }
        },
        model: {
            prop: "mode"
        },
        props: {
            mode: {
                type: String
            },
            mobilephone: {
                type: String
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                this.key += 1;
                let params = {
                    num: 4,
                    width: 100,
                    height: 30,
                    font: 20,
                    key: this.key,
                    mobilephone: this.mobilephone
                }
                let res = await User.GetCodeImage(params);
                if (res) {
                    this.imgSrc = res[1];
                    this.codeKey = res[0];
                    this.$emit('input', this.codeKey)
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .img-code {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
    }
</style>
