<template>
    <el-button :plain="plain" :size="size" :icon="icon" :type="type" @click="download" :loading="loading"
               :disabled="disabled">
        <slot></slot>
    </el-button>
</template>

<script>
    import Qs from "qs"

    export default {
        name: "",
        data() {
            return {
                loading: false
            }
        },
        props: {
            icon: {
                type: String,
                default: ""
            },
            type: {
                type: String,
                default: 'primary'
            },
            size: {
                type: String,
                default: 'small'
            },
            disabled: {
                type: Boolean,
                default: false
            },
            params: {
                type: Object,
                required: true
            },
            path: {
                type: String,
                required: true
            },
            plain: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            async download() {
                if (this.loading) {
                    return false
                }
                this.loading = true;
                let params = {
                    Parameter: this.params,
                    Paging: {
                        PageSize: 20,
                        PageIndex: 1,
                        PageTotal: 0
                    },
                    Token: this.$store.state.UserModule.token
                }
                let str = Qs.stringify(params, {
                    arrayFormat: 'repeat',
                    allowDots: true
                });
                let url = `${this.$BASE_URL}/${this.path}?${str}`;
                window.open(url, "_blank");
                this.loading = false;
            }
        }
    }
</script>

<style scoped>

</style>
