<template>
    <div class="info-null">
        <img src="../../assets/images/none.png" alt="">
        <div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InfoNull",
        data() {
            return {}
        }
    }
</script>

<style scoped>
    .info-null {
        padding: 30px 0;
        text-align: center;
        line-height: 40px;
    }
</style>
