import Vue from 'vue';
import {
    Navbar,
    TabItem,
    Button,
    Popup,
    Picker,
    Cell,
    Field,
    Actionsheet,
    Header,
    TabContainer,
    TabContainerItem,
    Checklist,
    InfiniteScroll
} from "mint-ui";

Vue.component(Navbar.name, Navbar);
Vue.component(TabItem.name, TabItem);
Vue.component(Button.name, Button);
Vue.component(Popup.name, Popup);
Vue.component(Picker.name, Picker);
Vue.component(Cell.name, Cell);
Vue.component(Field.name, Field);
Vue.component(Actionsheet.name, Actionsheet);
Vue.component(Header.name, Header);
Vue.component(TabContainer.name, TabContainer);
Vue.component(TabContainerItem.name, TabContainerItem);
Vue.component(Checklist.name, Checklist);
Vue.use(InfiniteScroll);
